import { setText, updatePost } from './scripts/posts';
import { setTracking } from './scripts/tracking';

/**
 * Initialises the component when passed an element
 *
 * @param {HTMLNode} rootElement The component dom node
 */
export default function(rootElement) {
  if (!rootElement) {
    return;
  }

  const updateInterval = parseInt(rootElement.dataset.updateInterval || '10000', 10);
  const template = rootElement.querySelector('template').content.firstElementChild;
  const parent = rootElement.closest('[data-role="parent"]');

  setTracking(rootElement);

  function update(data) {
    if (!data) {
      console.error('No data');
    }

    if (parent) {
      // switch parent layout if no posts, and hide component
      parent.dataset.blogState = data.posts.length === 0 ? 0 : 1;
      rootElement.classList[data.posts.length === 0 ? 'add' : 'remove']('ui-liveblog-mini--hidden');
    }

    // update title
    const title = rootElement.querySelector('[data-role="title"]');
    if (title) {
      setText(title, data.title);
    }

    // update posts
    // remove items if theyre no longer in the data
    const items = Array.from(rootElement.querySelectorAll('[data-post-id]'));
    items.forEach((item, index) => {
      if (!data.posts[index]) {
        item.parentNode.removeChild(item);
      }
    });

    data.posts.forEach((post, index) => {
      post.link = data.link;
      if (items[index]) {
        items[index].dataset.newPost = (Number(items[index].dataset.postId) !== post.postId);
        updatePost(items[index], post);
      } else {
        const clone = template.cloneNode(true);
        rootElement.querySelector('ul').appendChild(clone);
        updatePost(clone, post);
      }
    });

    setTracking(rootElement);
  }

  const endpoint = rootElement.dataset.endpoint;
  if (!endpoint) {
    console.error('No endpoint set');
    return;
  }

  function callEndpoint() {
    fetch(endpoint).then((response) => response.json()).then((json) => {
      if (json.checksum !== rootElement.dataset.checksum && json.timestamp > Number(rootElement.dataset.timestamp)) {
        if (!json.posts) {
          // article removed from basket so stop polling and remove blog
          clearInterval(rootElement.updateInterval);
          parent.dataset.blogState = 0;
          return;
        }
        update(json);
        rootElement.dataset.timestamp = json.timestamp;
      }
    }).catch((error) => {
      console.error(error);
    });
  }
  // call endpoint instantly to mitigate caching issues
  callEndpoint();
  rootElement.updateInterval = setInterval(callEndpoint, updateInterval);
}