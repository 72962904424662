export function setTracking(rootElement) {
  const posts = rootElement.querySelectorAll('[data-post-id]');

  if (!posts || posts.length === 0) {
    return;
  }

  posts.forEach((post, index) => {
    post.dataset.trackingLabel = `liveblog-mini/post/${index + 1}`;
  });
}