export function setText(el, value) {
  if (!value) {
    return;
  }

  if (el.textContent.trim() !== value.trim()) {
    el.textContent = value;
  }
}

export function setAttr(el, attr, value) {
  if (el.getAttribute(attr) !== attr) {
    el.setAttribute(attr, value);
  }
}

export function updatePost(item, post) {
  setAttr(item, 'data-post-id', post.postId);
  setText(item.querySelector('a'), post.title);
  setAttr(item.querySelector('a'), 'href', `${post.link}#${post.postId}`);
  setText(item.querySelector('[data-role="time-short"]'), post.time);
  setText(item.querySelector('[data-role="time-long"]'), post.timeA11y);
}